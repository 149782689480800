.Landing {
  background-color: #111010;
}
.common-width-landing {
  width: 1340px;
  margin-left: auto;
  margin-right: auto;
}

.LandingHeader {
  height: 100px;
  position: sticky;
  top: 0px;
  z-index: 9999;
}
.LandingHeader.scrolled {
  background-color: #111010;
}
.transparent-bg {
  background-color: transparent !important;
}
.LandingHeader li {
  list-style: none;
}
.LandingHeader nav a {
  color: #fff;
  font-size: 14px;
  font-family: var(--font-arabic-med);
  line-height: 18.2px;
  word-wrap: break-word;
}
.LandingHeader nav a.active {
  color: #9df74a;
}
.LandingHeader .gtranslate_wrapper .gtranslate_wrapper_modile {
  background-color: transparent;
}
.LandingHeader .button-wrapper a {
  font-size: 12px;
  font-family: var(--font-arabic-bold);
  word-wrap: break-word;
  height: 32px;
}
.LandingHeader .button-wrapper .signin {
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid #9df74a;
  color: #fff;
  margin: 0 8px;
}
.LandingHeader .button-wrapper .signup {
  border-radius: 8px;
  background: var(
    --Primary,
    linear-gradient(137deg, #9df74a 9.33%, #6fcf17 88.1%)
  );
  color: #111010;
  margin: 0 8px;
}
.LandingHeader .button-wrapper a:first-child,
.LandingHeader .button-wrapper a:nth-child(2) {
  border-color: #3cc293;
}
.LandingHeader .button-wrapper div p {
  font-family: var(--font-arabic-bold);
}

.LandingHeader .button-wrapper a:last-child {
  background: linear-gradient(to bottom right, #9be402, #1fa83c);
}

.LandingMain {
  height: 882px;
  background-size: cover;
  background-position: center;
  margin-top: -100px;
}

.LandingMain .main-pic-landing {
  display: flex;
  justify-content: center;
  align-items: center;
}
.LandingMain .main-pic-landing img {
  width: 80%;
  height: auto;
  margin-left: 80px;
  justify-content: center;
}
.LandingMain .main-coin img {
  top: -3rem;
}

.LandingMain .main-coin-landing {
  animation: moving 1s linear infinite alternate;
}
.movingarea {
  animation: movingnew 1s linear infinite alternate;
}
.LiquidityProvider-work {
  animation: movingLeft 10s linear infinite;
}
.circle-liquid {
  transform-origin: center;
  animation: reverse-rounding 10s linear infinite;
}

.game-coins-wrapper {
  animation: rounding 20s linear infinite;
}

.glowing-image {
  animation: zoomInOut 5s infinite;
}
.glowing-image-side {
  animation: zoomInOutSide 3s infinite;
}

.LandingMain .presentation-area > span,
.ManagementSolution .presentation > span {
  color: #9df74a;
  font-size: 14px;
  display: inline-block;
  text-transform: uppercase;
  font-family: var(--font-arabic-med);
  line-height: 22.4px;
  letter-spacing: 0.7px;
  word-wrap: break-word;
}
.LandingCompanyOverView .presentation-top span,
.CasinoPlatform > span,
.CasinoPlatform > p,
.GamingExcellence .GamingExcellenceFirst > span,
.sportsbook > .presentation > span {
  color: #9df74a;
  font-size: 14px;
  display: inline-block;
  text-transform: uppercase;
  font-family: var(--font-arabic-med);
  line-height: 22.4px;
  letter-spacing: 0.7px;
  word-wrap: break-word;
}
.CasinoPlatform > p {
  color: #9df74a;
  font-size: 14px;
  text-transform: uppercase;
  font-family: var(--font-arabic-med);
  line-height: 22.4px;
  letter-spacing: 0.7px;
  word-wrap: break-word;
  margin-left: 6%;
}
.LandingMain .presentation-area h1 {
  color: white;
  font-size: 77px;
  font-family: var(--font-arabic-bold);
  font-weight: 700;
  line-height: 96.25px;
  word-wrap: break-word;
}
.LandingMain .presentation-area h1 span {
  color: #9df74a;
  font-family: var(--font-arabic-bold);
}
.LandingMain .presentation-area p {
  color: #ffffff;
  font-size: 22px;
  font-family: var(--font-arabic-regular);
  line-height: 28.6px;
  word-wrap: break-word;
}
.LandingMain .presentation-area a {
  font-size: 18px;
  font-family: var(--font-arabic-bold);
  word-wrap: break-word;
  border: 1px solid transparent;
  height: 50px;
  color: #15191f;
  width: 203px;
  background: linear-gradient(to bottom right, #9be402, #1fa83c);
}

/* LandingCompanyOverView */
.ManagementSolution,
.LandingCompanyOverView {
  background-color: #111010;
}
.LandingCompanyOverView .blur2,
.ManagementSolution .blur2 {
  left: 50%;
  top: -450px;
  transform: translateX(-50%);
}
.cansioSports .blur2 {
  left: 0%;
  top: 0px;
  transform: translateX(0%);
  z-index: 1;
}

.LandingCompanyOverView .presentation-top h1 {
  color: white;
  font-size: 58px;
  font-family: "Arabic Pro Bold";
  line-height: 69.6px;
  word-wrap: break-word;
}
.LandingCompanyOverView .presentation-top p {
  color: #b7b8bc;
  font-size: 18px;
  font-family: "Arabic Pro Reg";
  line-height: 21.6px;
  word-wrap: break-word;
  width: 556px;
  text-align: center;
}
.LandingCompanyOverView .presentation-top span {
  width: 115px;
}
.LandingCompanyOverView .cards-wrapper .card {
  border-radius: 8px;
  border: 1px solid #9435e2;
  background: #1a1a1b;
}
.LandingCompanyOverView .cards-wrapper .card h1 {
  color: white;
  font-size: 29px;
  font-family: var(--font-arabic-regular);
  line-height: 31.9px;
  word-wrap: break-word;
}
.ForPlayersHeading {
  color: white;
  font-size: 45px;
  font-family: var(--font-arabic-regular);
  line-height: 45px;
  word-wrap: break-word;
}
.ForPlayersDescription {
  color: #aeb2b1;
  font-size: 18px;
  font-family: var(--font-arabic-regular);
  line-height: 21.6px;
  word-wrap: break-word;
}
.LandingCompanyOverView .cards-wrapper .card p {
  color: #aeb2b1;
  font-size: 16px;
  font-family: var(--font-arabic-regular);
  line-height: 20px;
  word-wrap: break-word;
}

/* cansioSports */

.CasinoPlatform > span {
  width: 233px;
}
.CasinoPlatform > h1,
.GamingExcellence .GamingExcellenceFirst > h1 {
  color: white;
  font-size: 58px;
  font-family: var(--font-arabic-light);
  font-weight: 300;
  word-wrap: break-word;
}
.CasinoPlatform > h1 span,
.GamingExcellence .GamingExcellenceFirst > h1 span {
  color: #9df74a;
  font-family: var(--font-arabic-bold);
}
.CasinoPlatform .CasinoPlatformFirstWrapper {
  margin-bottom: 60px;
}
.CasinoPlatform .CasinoPlatformFirstWrapper > p,
.GamingExcellence .GamingExcellenceFirst > .CasinoPlatformFirstWrapper p {
  color: #b7b8bc;
  font-size: 18px;

  font-family: "Arabic Pro Reg";
  line-height: 21.6px;
  word-wrap: break-word;
}
.CasinoPlatform .CasinoPlatformFirstWrapper a {
  font-size: 18px;
  font-family: var(--font-arabic-bold);
  word-wrap: break-word;
  border: 1px solid transparent;
  height: 50px;
  color: #111010;
  width: 203px;
  background: linear-gradient(to bottom right, #9be402, #1fa83c);
}

.CasinoPlatform .card {
  background-color: #2e3543;
  border: 1px solid #323743;
}
.CasinoPlatform .card h1 {
  color: white;
  font-size: 23px;
  font-family: "Arabic Pro Medium";
  line-height: 26.45px;
  word-wrap: break-word;
}
.CasinoPlatform .card p {
  color: #b7b8bc;
  font-size: 14px;
  font-family: "Arabic Pro Reg";
  line-height: 18.2px;
  word-wrap: break-word;
}

/* long-bar-wrapper */
.long-bar-wrapper {
  background-color: #111010;
}

/* deployment */
.deployment > h1 {
  color: white;
  font-size: 45px;
  font-family: var(--font-arabic-regular);
  line-height: 45px;
  word-wrap: break-word;
}
.deployment > p {
  color: #aeb2b1;
  font-size: 18px;
  font-family: var(--font-arabic-regular);
  line-height: 21.6px;
  word-wrap: break-word;
}
/* ManagementSolution */

.ManagementSolution .presentation > h1,
.sportsbook > .presentation > h1 {
  color: white;
  font-size: 58px;
  font-family: var(--font-arabic-light);
  line-height: 69.6px;
  word-wrap: break-word;
  width: 860px;
}
.sportsbook > .presentation > h1 span,
.ManagementSolution .presentation > h1 span {
  font-family: var(--font-arabic-bold);
  color: #6FCF17;
}

.ManagementSolution .presentation > p,
.sportsbook > .presentation > p {
  color: #aeb2b1;
  font-size: 20px;
  font-family: var(--font-arabic-regular);
  line-height: 26px;
  word-wrap: break-word;
}

.ManagementSolution .cards-wrapper {
  margin-top: 60px;
}
.ManagementSolution .card {
  background-color: #212632;
  border: 1px solid #9435e2;
}
.ManagementSolution .card h1 {
  color: white;
  font-size: 22px;
  font-family: "Arabic Pro Medium";
  line-height: 26.45px;
  word-wrap: break-word;
}
.ManagementSolution .card p {
  color: #b7b8bc;
  font-size: 16px;
  font-family: "Arabic Pro Reg";
  line-height: 20.8px;
  word-wrap: break-word;
}

.GamingExcellence > span {
  width: 199px;
}
.GamingExcellence .GamingExcellenceFirst > .CasinoPlatformFirstWrapper p {
  width: unset;
}

.PlayerExp .inner-presentation > h1 {
  color: white;
  font-size: 36px;
  font-family: "Arabic Pro Bold";
  line-height: 37.8px;
  word-wrap: break-word;
}
.PlayerExp .inner-presentation > p {
  color: #b7b8bc;
  font-size: 18px;
  font-family: "Arabic Pro Reg";
  line-height: 21.6px;
  word-wrap: break-word;
}
.PlayerExp .cards-wrapper .card {
  border-radius: 8px;
  border: 1px solid #9435e2;
  background: #1a1a1b;
}
.PlayerExp .cards-wrapper .card h1 {
  color: white;
  font-size: 23px;
  font-family: "Arabic Pro Medium";
  line-height: 26.45px;
  word-wrap: break-word;
}
.PlayerExp .cards-wrapper .card p {
  color: #b7b8bc;
  font-size: 16px;
  font-family: "Arabic Pro Reg";
  line-height: 19.2px;
  word-wrap: break-word;
}

.PaymentService .card {
  border: 1px solid #323743;
}

.sportsbook {
  background: transparent;
}
.sportsbook > img:first-child {
  left: 50%;
  top: -450px;
  transform: translateX(-50%);
}

.sportsbook > .presentation > span {
  width: 142px;
}

.BettingFeature {
  background-color: #212632;
}
.BettingFeature .card {
  background: transparent;
  border: none;
}
.BettingFeature .card h1 {
  font-size: 20px;
}

.BettingFeature .card p {
  color: #b7b8bc;
  font-size: 16px;
  line-height: 20.8px;
  word-wrap: break-word;
}
.ServicesWrapper {
  background-color: transparent;
}
.Services {
  margin-bottom: 0px;
}
.Services .card {
  background: rgba(255, 255, 255, 0.04);
  border-radius: 8px;
  border: 1px rgba(255, 255, 255, 0.7) solid;
  backdrop-filter: blur(8px);
}

.CustomerSupport .card {
  padding: 30px;
  background-color: #212632;
  border: 1px solid #323743;
}
.CustomerSupport .card > h1 {
  color: white;
  font-size: 23px;
  font-family: "Arabic Pro Medium";
  line-height: 26.45px;
  word-wrap: break-word;
}
.CustomerSupport .card > p {
  color: #b7b8bc;
  font-size: 16px;
  margin-top: 12px;
  font-family: "Arabic Pro Reg";
  line-height: 19.2px;
  word-wrap: break-word;
}
.CustomerSupport .card li,
.PaymentProviders .presentation-right li {
  color: #b7b8bc;
  position: relative;
  font-size: 16px;
  font-family: "Arabic Pro Reg";
  line-height: 15.2px;
  word-wrap: break-word;
  margin-bottom: 15px;
  padding-left: 2rem;
}
.CustomerSupport .card li:not(.head):after,
.PaymentProviders .presentation-right li:after {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0px;
  top: -2px;
  content: "";

  background-size: 20px;
  background-repeat: no-repeat;
}
.CustomerSupport .card li.head {
  padding-left: 0rem;
  color: white;
  font-size: 18px;
  font-family: "Arabic Pro Medium";
  line-height: 21.6px;
  word-wrap: break-word;
}
.CustomerSupport .card .presentation {
  width: 367px;
}
.CustomerSupport .card .presentation h1 {
  color: white;
  font-size: 23px;
  font-family: "Arabic Pro Medium";
  line-height: 26.45px;
  word-wrap: break-word;
}
.CustomerSupport .card .presentation p {
  width: 100%;
  color: #b7b8bc;
  font-size: 18px;
  font-family: "Arabic Pro Reg";
  line-height: 21.6px;
  word-wrap: break-word;
}
.CustomerSupport .card.col-span-3 ul {
  width: 757px;
}
.CustomerSupport .card.col-span-3 ul h1 {
  color: white;
  font-size: 18px;
  font-family: "Arabic Pro Medium";
  line-height: 21.6px;
  word-wrap: break-word;
}
.CustomerSupport .card.col-span-3 ul p {
  color: #b7b8bc;
  font-size: 16px;
  font-family: "Arabic Pro Reg";
  line-height: 19.2px;
  word-wrap: break-word;
  margin-top: 12px;
}

.Partners .slide-wrapper > h1 {
  color: white;
  font-size: 36px;
  font-family: var(--font-arabic-regular);
  line-height: 37.8px;
  word-wrap: break-word;
}

.Partners .splide__arrows {
  display: flex;
  align-items: center;
  position: absolute;
  top: -55px;
  right: 0px;
}
.Partners .splide__arrows > * + * {
  margin-left: 10px;
}
.Partners .splide__arrow {
  width: 56px;
  height: 56px;
  background: #252525;
  border-radius: 8px;
  border: 1px rgba(73.87, 77.46, 86.06, 0.8) solid;
  opacity: 1 !important;
  position: static;
}
.Partners .splide__arrow svg {
  width: 24px;
  height: 24px;
  fill: #fff !important;
}
.Partners .splide__arrow:disabled {
  opacity: 0.5 !important;
}
.Partners .slide-wrapper .card {
  background-color: #252525;
  border: 1px solid #3c403f;
  border-radius: 8px;
}
.Partners .slide-wrapper .card .presentation {
  border-top: 1px solid #3c403f;
}
.Partners .slide-wrapper .card h1 {
  color: white;
  font-size: 18px;
  font-family: "Arabic Pro Medium";
  line-height: 21.6px;
  word-wrap: break-word;
  width: unset;
}
.Partners .slide-wrapper .card p {
  color: #b7b8bc;
  font-size: 16px;
  font-family: "Arabic Pro Medium";
  line-height: 20px;
  word-wrap: break-word;
  width: unset;
}
.Partners .presentation_down h1 {
  color: white;
  font-size: 36px;
  font-family: "Arabic Pro Bold";
  line-height: 37.8px;
  word-wrap: break-word;
}
.Partners .presentation_down p {
  width: 839px;
}

.Partners .coins-wapper {
  width: 1052px;
  margin-top: 60px;
  background: rgba(255, 255, 255, 0.04);
  border-radius: 16px;
  border: 1px rgba(255, 255, 255, 0.2) solid;
  backdrop-filter: blur(8px);
}
.PaymentProviders .presentation p {
  width: 100%;
}
.PaymentProviders .presentation-right > h1 {
  color: white;
  font-size: 28px;
  font-family: "Arabic Pro Medium";
  line-height: 32.2px;
  word-wrap: break-word;
}

.LandingFooter {
  padding: 120px 0px 140px 0px;
}
.LandingFooter .landing-footer-top {
  margin-bottom: 60px;
}
.LandingFooter .landing-footer-top p {
  color: #b7b8bc;
  font-size: 18px;
  font-family: var(--font-arabic-med);
  line-height: 21.6px;
  word-wrap: break-word;
}
.LandingFooter .landing-footer-top h1 {
  color: white;
  font-size: 23px;
  font-family: "Arabic Pro Medium";
  line-height: 26.45px;
  word-wrap: break-word;
}
.LandingFooter .landing-footer-grid-area {
  margin-bottom: 60px;
}
.LandingFooter .landing-footer-grid-area .head {
  color: white;
  font-size: 23px;
  font-family: "Arabic Pro Medium";
  line-height: 26.45px;
  word-wrap: break-word;
}
.LandingFooter .landing-footer-grid-area li a {
  color: #9a9ba1;
  font-size: 18px;
  font-family: "Arabic Pro Medium";
  line-height: 21.6px;
  word-wrap: break-word;
  text-decoration: none;
}

.LandingFooter .copyright-area h1 {
  color: #b7b8bc;
  font-size: 18px;
  font-family: "Arabic Pro Medium";
  line-height: 21.6px;
  word-wrap: break-word;
}
.LandingFooter .copyright-area li {
  list-style: none;
}
.LandingFooter .copyright-area nav > * + * {
  margin-left: 2rem;
}
.LandingFooter .copyright-area a {
  color: #b7b8bc;
  font-size: 18px;
  font-family: "Arabic Pro Medium";
  line-height: 21.6px;
  word-wrap: break-word;
}
.LandingFooter .copyright-area nav li {
  position: relative;
}
.LandingFooter .copyright-area nav li:first-child::after {
  display: none;
}
.LandingFooter .copyright-area nav li::after {
  width: 8.5px;
  height: 8.5px;
  background-color: #b7b8bc;
  border-radius: 50%;
  position: absolute;
  left: -20px;
  top: 6px;
  content: "";
}
.PaymentProviders .blur2 {
  left: -100px;
  top: -200px;
  transform: translateX(0%);
  z-index: 1;
}

#partners .splide.is-initialized {
  padding: 0px;
}

.react-tel-input .country-list li {
  color: #ffffff;
}
.react-tel-input .country-list li.highlight,
.react-tel-input .country-list li:hover {
  background-color: #252525 !important;
}

/* Stakers */
.stakers {
  margin: 0 20px;
  justify-content: center;
}
.stakers .card {
  background-color: #252525;
  border: 1px solid #3c403f;
  padding: 20px;
  margin: 0 auto;
  border-radius: 8px;
  box-sizing: border-box;
  /* max-width: 400px; */
  height: 650px;
  /* width: 100%; */
}
.stakers .card img {
  width: 100%;
  height: auto;
}
.stakers .card h1 {
  color: #fff;
  font-family: var(--font-arabic-reg);
  font-size: 23px;
  font-weight: 500;
  line-height: 115%;
}
.stakers .card p {
  color: #aeb2b1;
  font-family: var(--font-arabic-reg);
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
}
.stakers .slick-prev,
.stakers .slick-next {
  display: none !important;
}

/* Partner */
/* .partners {
  margin: 0 20px;
  justify-content: center;
}
.partners .card {
  background-color: #252525;
  border: 1px solid #3C403F;
  padding: 20px;
  margin: 0 auto; 
  border-radius: 8px;
  box-sizing: border-box;
  max-width: 400px;
  height: 650px;
  width: 100%; 
}
.partners .card img {
  width: 100%;
  height: auto;
}
.partners .card h1 {
  color: #FFF;
  font-family: var(--font-arabic-reg);
  font-size: 23px;
  font-weight: 500;
  line-height: 115%;
}
.partners .card p {
  color: #AEB2B1;
  font-family: var(--font-arabic-reg);
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
} */
.partners .slick-prev,
.partners .slick-next {
  display: none !important;
}

@media screen and (max-width: 1350px) {
  .common-width-landing {
    width: 95%;
  }
}

@media screen and (max-width: 1200px) {
  .LandingMain {
    height: unset;
    padding: 5rem 0rem;
  }
  .LandingMain > .grid {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
  .LandingMain > .grid > *:first-child {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .Landing .rockety {
    right: 120px;
  }
  .Landing .rocket {
    left: unset;
    right: 0px;
  }

  .LandingCompanyOverView > .grid {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
  .ManagementSolution .cards-wrapper {
    grid-template-columns: 1fr 1fr;
  }
  /* .ManagementSolution .grid {
    grid-template-columns: 1fr;
  } */
  .PlayerExp .cards-wrapper .card {
    grid-column: span 1;
  }
  .CustomerSupport .card {
    grid-column: span 1;
  }
  .LandingFooter .landing-footer-grid-area {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .PlayerExp .presentation > p {
    width: 100%;
  }
  .Partners .coins-wapper {
    width: 100%;
  }
  .LandingMain .main-coin img {
    display: none;
  }
  .LandingMain .presentation-area > span {
    margin-top: 10%;
  }
}
@media screen and (max-width: 900px) {
  .CasinoPlatform > h1,
  .GamingExcellence .GamingExcellenceFirst > h1,
  .ManagementSolution .presentation > h1,
  .sportsbook > .presentation > h1 {
    font-size: 35px;
    line-height: unset;
  }
  .ManagementSolution .presentation > h1 {
    font-size: 35px !important;
  }
  .ManagementSolution .presentation > p,
  .sportsbook > .presentation > p {
    width: 100%;
  }
  .CasinoPlatform > .grid {
    grid-template-columns: 1fr;
  }
  .CasinoPlatform .CasinoPlatformFirstWrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .CasinoPlatform .CasinoPlatformFirstWrapper > p,
  .GamingExcellence .GamingExcellenceFirst > .CasinoPlatformFirstWrapper p {
    width: 100%;
  }
  .CasinoPlatform .CasinoPlatformFirstWrapper a {
    margin-top: 2rem;
  }
  .CustomerSupport .card {
    flex-direction: column;
    align-items: flex-start;
  }
  .CustomerSupport .card .presentation {
    margin-bottom: 2rem;
  }
  /* .LandingFooter .landing-footer-top {
    flex-direction: column;
    align-items: flex-start;
  } */
  .LandingFooter .landing-footer-top > * + * {
    /* margin-top: 3rem; */
  }
  .LandingFooter .copyright-area {
    flex-direction: column;
  }
  .LandingFooter .copyright-area > * + * {
    margin-top: 1rem;
  }
  .PaymentProviders .presentation-right > .flex {
    justify-content: flex-start;
  }
  .PaymentProviders .presentation-right > .flex > * + * {
    margin-left: 1rem;
  }
  .Partners .coins-wapper {
    flex-direction: column;
  }
  .LandingHeader nav {
    position: fixed;
    padding-top: 4.4rem;
    top: 0px;
    height: 100vh;
    width: 200px;
    flex-direction: column;
    right: -100%;
    transition: 0.3s ease all;
    z-index: 999;
    background: #fff;
  }
  .gtranslate_wrapper_modile {
    display: block;
  }
  .LandingHeader .button-wrapper {
    flex: 1;
    justify-content: flex-end;
  }
  .LandingHeader .button-wrapper > * + * {
    margin-left: 5px;
  }
  .burger-btn {
    display: block;
    z-index: 9999;
  }
  .LandingHeader nav.active {
    right: 0px;
  }
  .button-wrapper-2 {
    display: none;
  }

  .LandingHeader nav a,
  .LandingHeader nav a.active {
    color: #000;
  }
  .LandingHeader .button-wrapper-1 {
    display: flex;
    flex: unset;
  }
  .LandingHeader nav > * {
    margin-bottom: 1rem;
    margin-right: 0px;
  }
  .ManagementSolution .presentation > h1,
  .sportsbook > .presentation > h1 {
    width: 100%;
  }
  .CustomerSupport .card.col-span-3 ul {
    width: 100%;
  }
  .LandingFooter .landing-footer-top p {
    width: 100%;
  }
  .LandingFooter .landing-footer-grid-area {
    grid-template-columns: 1fr;
  }
  .spider-img {
    display: none;
  }
  .deployment {
    width: 100%;
    min-width: 100%;
  }
  .deploymentwrapper {
    flex-direction: column !important;
  }
  .LandingHeader {
    background-color: #111010;
  }
  .LandingMain .main-pic-landing img {
    margin-left: auto;
    margin-right: auto;
  }
  .stakers .card {
    margin: 0 auto;
  }
}
@media screen and (max-width: 600px) {
  .LandingMain .main-coin-landing {
    top: 20px;
  }
  .game-coins-wrapper img {
    width: 60px;
  }
  .game-coins-wrapper img:first-child {
    left: -10px;
  }
  .game-coins-wrapper img:nth-child(4) {
    right: -10px;
  }
  .game-coins-wrapper img:nth-child(2) {
    top: -10px;
  }
  .game-coins-wrapper img:nth-child(3) {
    top: -10px;
  }
  .game-coins-wrapper img:nth-child(5) {
    bottom: 3rem;
  }
  .game-coins-wrapper img:nth-child(7) {
    bottom: 3rem;
  }
  .provide-main-pic {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .LandingHeader {
    height: 70px;
  }
  .Partners .splide__arrow {
    width: 40px;
    height: 40px;
  }
  .LiquidityProvider-grid-img {
    flex-wrap: wrap;
    margin-bottom: 3rem;
  }
  .rockety {
    display: none;
  }
  .Landing .rockety2 {
    width: unset !important;
    display: block;
    right: 60px;
    bottom: 0.5rem;
  }
  .LandingHeader .logo {
    width: 150px;
  }
  .LandingMain .presentation-area {
    margin-top: 50px;
  }
  .LandingMain .presentation-area h1 {
    font-size: 40px;
    line-height: unset;
  }
  .LandingMain .main-pic-landing img {
    margin-left: auto;
    margin-right: auto;
  }
  .deployment > h1 {
    font-size: 30px;
  }
  .Landing .rocket {
    right: unset;
    top: 0px;
    left: -150px;
  }
  .LandingMain .presentation-area p,
  .PlayerExp .inner-presentation > p,
  .ManagementSolution .presentation > p,
  .sportsbook > .presentation > p,
  .LandingFooter .landing-footer-top p,
  .CustomerSupport .card .presentation p,
  .deployment > p {
    font-size: 16px;
    line-height: unset;
  }
  .ManagementSolution .card h1 {
    font-size: 18px;
  }
  .blur-cards {
    display: none;
  }
  .LandingCompanyOverView .presentation-top h1,
  .CasinoPlatform > h1,
  .GamingExcellence .GamingExcellenceFirst > h1,
  .ManagementSolution .presentation > h1,
  .sportsbook > .presentation > h1,
  .Partners .slide-wrapper > h1,
  .PlayerExp .inner-presentation > h1 {
    font-size: 30px;
    line-height: unset;
  }
  .CasinoPlatform .CasinoPlatformFirstWrapper > p,
  .GamingExcellence .GamingExcellenceFirst > .CasinoPlatformFirstWrapper p,
  .LandingFooter .landing-footer-grid-area li a {
    font-size: 16px;
  }
  .LandingCompanyOverView .presentation-top p {
    width: 95%;
    font-size: 16px;
  }
  .LandingCompanyOverView .cards-wrapper .card h1 {
    font-size: 22px;
  }
  .PaymentProviders .presentation-right > .flex img {
    width: 30px;
  }
  .PaymentProviders .presentation-right > .flex img:last-child {
    width: 90px;
  }
  .ManagementSolution .grid {
    display: block;
  }
  .ManagementSolution .grid > * + * {
    margin-top: 1rem;
  }
  .CasinoPlatform .card,
  .PlayerExp .cards-wrapper .card,
  .deployment {
    width: 100%;
  }
  .deployment {
    min-width: 100%;
  }
  .CasinoPlatform > p {
    margin-left: 3%;
  }

  /* padding stylign */

  .CasinoPlatform .CasinoPlatformFirstWrapper a {
    font-size: 15px;
    width: 150px;
    height: 45px;
  }
  .CustomerSupport .card .presentation {
    width: 100%;
  }
  .LandingFooter .copyright-area a {
    font-size: 12px;
  }
  .LandingFooter .copyright-area > * + * {
    justify-content: space-between;
    width: 100%;
  }
  .LandingFooter .copyright-area nav li::after {
    display: none;
  }
  .LandingFooter {
    padding: 60px 0px 80px 0px;
  }
  .Partners .slide-wrapper .card h1 {
    font-size: 16px !important;
  }
  .Partners .slide-wrapper > h1 {
    font-size: 25px;
  }
  /* .Landing .pt-\[160px\] {
    padding-top: 100px;
  } */
  .Landing .pb-\[160px\] {
    padding-bottom: 100px;
  }
  .LandingMain {
    overflow: hidden;
    position: relative;
    padding-bottom: 0px !important;
  }
  .LandingMain > .grid > *:first-child {
    bottom: -100px;
    right: -100px;
  }
}

@keyframes moving {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.04);
  }
}
@keyframes movingnew {
  0% {
    transform: translateX(-50%) scale(1);
  }
  100% {
    transform: translateX(-50%) scale(1.04);
  }
}
@keyframes movingLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes rounding {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes reverse-rounding {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes zoomInOut {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

@keyframes zoomInOutSide {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
