.TermsConditions {
  background-image: url("../../img/landing/blur-terms.png");
  background-size: cover;
}
.TermsConditions .contact-link {
  color: #6fcf17;
  font-size: 18px;
  font-family: var(--font-arabic-med);
  line-height: 21.6px;
  word-wrap: break-word;
}
.TermsConditions .main-section > h1,
.legal-message-heading {
  color: white;
  font-size: 77px;
  font-family: var(--font-arabic-bold);
  line-height: 92.4px;
  word-wrap: break-word;
}
.legal-message-heading {
  font-size: 58px;
}
.TermsConditions .main-section > p {
  color: #c8cbca;
  font-size: 22px;
  font-family: var(--font-arabic-reg);
  line-height: 28.6px;
  word-wrap: break-word;
}

.TermsConditions .main-section .heading-li {
  color: white;
  font-size: 36px;
  font-family: var(--font-arabic-bold);
  line-height: 37.8px;
  word-wrap: break-word;
}

.TermsConditions .main-section li:not(.heading-li) {
  color: #c8cbca;
  font-size: 20px;
  font-family: var(--font-arabic-reg);
  line-height: 32px;
  word-wrap: break-word;
}
.TermsConditions .main-section li:not(.heading-li) a {
  color: #47d6a3;
  text-decoration: underline;
}
.TermsConditions .main-section li:not(.heading-li) span.bold {
  color: #fff;
  font-family: var(--font-arabic-bold);
}

.TermsConditions .main-section .outerlist {
  font-family: var(--font-arabic-reg);
  font-size: 22px;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}
.TermsConditions .main-section .outerlist span {
  font-family: var(--font-arabic-bold);
}
.TermsConditions .main-section .outerlist h1 {
  font-size: 36px;
  font-family: var(--font-arabic-bold);
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}
