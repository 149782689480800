@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primaryColor: #15191f;
  --secondaryColor: #212632;
  --paragraphColor: #959699;
  --innersecondaryColor: #2e3543;
  --borderColor: #323743;
  --headingColor: #fff;

  /* fonts */
  --font-arabic-med: "Arabic Pro Medium";
  --font-arabic-reg: "Arabic Pro Reg";
  --font-arabic-bold: "Arabic Pro Bold";
  --font-arabic-light: "Arabic Pro Light";
}

html {
  scroll-behavior: smooth;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
@font-face {
  font-family: "Arabic Pro Light";
  src: url("./assets/font/BasisGrotesqueArabicPro-Light.ttf");
}
@font-face {
  font-family: "Arabic Pro Medium";
  src: url("./assets/font/BasisGrotesqueArabicPro-Medium.ttf");
}
@font-face {
  font-family: "Arabic Pro Reg";
  src: url("./assets/font/BasisGrotesqueArabicPro-Regular.ttf");
}

@font-face {
  font-family: "Arabic Pro Bold";
  src: url("./assets/font/BasisGrotesqueArabicPro-Bold.ttf");
}

body {
  background-color: #111010;
}
#google_translate_element2 + select {
  display: none !important;
}
.gt_selector {
  outline: none;
  cursor: pointer;
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

aside {
  background-color: var(--secondaryColor);
  border: 1px solid var(--borderColor);
  border-bottom-right-radius: 15px;
  min-width: 240px;
  width: 240px;
  height: 100vh;
  position: sticky;
  top: 0px;
}
aside .bonus-area {
  background-color: var(--innersecondaryColor);
}
aside .bonus-area h1 {
  font-family: "Arabic Pro Medium";
  color: var(--paragraphColor);
  font-size: 14px;
  font-weight: 500;
  line-height: 135%;
}
aside .bonus-area span {
  font-family: "Arabic Pro Bold";
  color: var(--primaryColor);
  font-size: 10px;
  line-height: 135%;
  border-radius: 4px;
  background: var(--Active, #46ca22);
}

aside .first-button-area p,
aside .refer-button p {
  font-family: "Arabic Pro Medium";
  color: var(--headingColor);
  font-size: 12px;
  line-height: 135%;
}
aside .refer-button p {
  font-family: "Arabic Pro Medium";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 18.9px */
}
aside .first-button-area > *:first-child {
  background: linear-gradient(to right, #6d2a69, #433150);
}
aside .first-button-area > *:last-child {
  background: linear-gradient(to right, #79642d, #48463c);
}
aside .refer-button {
  background: linear-gradient(to right, #4c3e1a, #15191e, #15191e, #15191e);
}

aside .breaker {
  margin-bottom: 30px;
}

.tabs-area ul {
  background-color: var(--primaryColor);
  padding: 0px;
  height: 43px;
  border: 1px solid var(--borderColor);
}

.tabs-area ul li > .shadow {
  background-color: var(--innersecondaryColor);
}
.tabs-area ul li p {
  color: var(--paragraphColor);
  font-size: 14px;
  font-family: "Arabic Pro Medium";
  font-weight: 500;
  line-height: 135%; /* 18.9px */
  transition: 0.3s ease all;
}
.tabs-area ul li.active p {
  color: var(--headingColor);
}
.tabs-area ul li > * {
  pointer-events: none;
}

/* header area */
header {
  background-color: var(--secondaryColor);
  border-bottom: 1px solid var(--borderColor);
  position: sticky;
  top: 0px;
  z-index: 9999;
}
header .tabs-area ul {
  height: 32px;
  width: 204px;
}
header .select-bar > div {
  background-color: var(--innersecondaryColor);
  padding: 5px;
}
header .select-bar > div p {
  color: var(--headingColor);
  font-size: 12px;
  font-family: "Arabic Pro Medium";
  line-height: 135%; /* 16.2px */
}
header .select-bar > div p span {
  color: var(--paragraphColor);
}

header .deposit-button {
  background: linear-gradient(180deg, #ffc83e 0%, #ffb700 49.48%, #d09500 100%);
  color: var(--primaryColor);
  font-family: "Arabic Pro Bold";
  font-size: 12px;
  border: 1px solid transparent;
}
header .border-button {
  color: var(--headingColor);
  background: transparent;
  border-color: #ffbc0e;
}
header .icon-btn {
  background-color: var(--innersecondaryColor);
  border: 1px solid var(--borderColor);
}
header .icon-buttons > button {
  background-color: var(--innersecondaryColor);
  border: 1px solid var(--borderColor);
}

header .Avatar {
  border: 1px solid var(--borderColor);
  padding: 2px;
}
.menu-list {
  background-color: #212632 !important;
  border: none;
  padding-left: 0px;
  padding-right: 0px;
}
.menu-list > * {
  color: #8f949d !important;
  border-radius: 0px;
}
.menu-list > *:hover {
  color: #fff !important;
  background-color: #2f3643 !important;
}

header .Avatar .arrow-icon {
  width: 10px;
  height: 10px;
  background-color: var(--borderColor);
}
header .Avatar .arrow-icon {
  right: 1px;
  bottom: 0px;
}

/* footer */
footer {
  background: url("./assets/img/landing/footer-bg.png");
  background-size: cover;
}

footer .footer-width {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

footer .left-area-footer-why h1,
footer .logo-footer-area h1,
footer .cols-footer-area h1 {
  color: var(--headingColor);
  font-size: 18px;
  font-family: "Arabic Pro Medium";
  line-height: 21.6px;
  word-wrap: break-word;
}
footer .left-area-footer-why p,
footer .logo-footer-area p,
footer .cols-footer-area p {
  color: var(--paragraphColor);
  font-size: 14px;
  font-family: "Arabic Pro Reg";
  line-height: 18.2px;
  word-wrap: break-word;
}
footer .left-area-footer-why h2 {
  color: white;
  font-size: 16px;
  font-family: "Arabic Pro Medium";
  line-height: 20px;
  word-wrap: break-word;
}
footer .right-area-footer-why h1 {
  color: var(--headingColor);
  font-size: 18px;
  font-family: "Arabic Pro Medium";
  line-height: 21.6px;
  word-wrap: break-word;
}
footer .right-area-footer-why textarea {
  background-color: var(--primaryColor);
  border: 1px solid var(--borderColor);
  outline: none;

  font-size: 14px;
  font-family: "Arabic Pro Reg";
  line-height: 18.2px;
  word-wrap: break-word;
}
footer .right-area-footer-why textarea::placeholder {
  color: var(--paragraphColor);
}

footer .right-area-footer-why button {
  background: linear-gradient(180deg, #ffc83e 0%, #ffb700 49%, #d09500 100%);

  justify-content: center;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  color: var(--primaryColor);
  font-size: 16px;
  font-family: "Arabic Pro Bold";
  font-weight: 700;
  line-height: 21.6px;
  word-wrap: break-word;
}

footer .right-area-footer-why .submit p {
  color: var(--paragraphColor);
  font-size: 14px;
  font-family: "Arabic Pro Reg";
  line-height: 18.2px;
  word-wrap: break-word;
}
footer .right-area-footer-why > p {
  color: var(--paragraphColor);
  font-size: 14px;
  font-family: "Arabic Pro Reg";
  line-height: 18.2px;
  word-wrap: break-word;
}
footer .right-area-footer-why > p a {
  color: #ffc83e;
}

footer .list-area-footer ul .head {
  color: var(--headingColor);
  font-size: 16px;
  font-family: "Arabic Pro Medium";
  line-height: 20px;
  word-wrap: break-word;
  margin-bottom: 29px;
}
footer .list-area-footer ul li:not(.head) a {
  color: var(--paragraphColor);
  font-size: 14px;
  font-family: "Arabic Pro Reg";
  line-height: 18.2px;
  word-wrap: break-word;
  margin-bottom: 19px;
}

footer .logo-footer-area .right-area p {
  width: 70%;
}
footer .cols-footer-area p span {
  color: var(--headingColor);
}

footer .copyright-area p {
  color: var(--paragraphColor);
  font-size: 16px;
  font-family: "Arabic Pro Reg";
  line-height: 19.2px;
  word-wrap: break-word;
}

footer .icons-wrapper h1 {
  color: var(--headingColor);
  font-size: 16px;
  font-family: "Arabic Pro Medium";
  line-height: 20px;
  word-wrap: break-word;
}
footer .icons-wrapper a {
  color: #ffc83e;
  font-size: 16px;
  font-family: "Arabic Pro Medium";
  line-height: 19.2px;
  word-wrap: break-word;
}

/* LayerUp */
.LayerUp {
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0%;
  top: 0px;
  background: rgba(21, 25, 31, 0.8);
  backdrop-filter: blur(4px);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* CheckboxStyle */
.CheckboxStyle label {
  border: 2px solid var(--paragraphColor);
  display: flex;
}

.CheckboxStyleSignUp label {
  width: 18px;
  height: 18px;
  border-color: #6fcf17;
  border-radius: 2px;
  margin-top: 4px;
}
.CheckboxStyleSignUp input:checked + label {
  border-color: transparent;
  background: linear-gradient(144deg, #9be402 -47.44%, #1fa83c 140.74%);
}
.CheckboxStyle input:checked + label span {
  background: var(--paragraphColor);
}
.scrollOut {
  transition: 1s ease all;
  opacity: 0;
  transform: translateY(100px);
}
p.scrollOut,
h1.scrollOut,
span.scrollOut,
button.scrollOut {
  opacity: 1;
  transform: translateY(0px);
}
.scrollIn {
  opacity: 1;
  transform: translateY(0px);
}

/* Switch Component */
.Switch input {
  display: none;
}
.Switch label {
  width: 32px;
  height: 19px;
  background-color: #393f4d;
  border-radius: 99px;
  cursor: pointer;
  padding: 0px 4px;
  transition: 0.3s ease all;
}
.Switch label span {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  transition: 0.3s ease all;
  background-color: #ffffff;
}
.Switch input:checked + label {
  background: linear-gradient(180deg, #ffc83e 0%, #ffb700 49%, #d09500 100%);
}
.Switch input:checked + label span {
  transform: translateX(11px);
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #ffc83e;
  width: 30px;
  height: 30px;
}

.Toastify__toast-container {
  z-index: 999999 !important;
}
.welcome-ref-popup {
  background: rgba(21, 25, 31, 0.9);
  backdrop-filter: blur(2px);
  z-index: 9999999999999;
}
.FaqQues p {
  color: #aeb2b1;
  font-family: var(--font-arabic-med);
  font-size: 16px;
  margin-bottom: 16px;
}
.FaqQues h2 {
  color: #fff;
  font-family: var(--font-arabic-med);
  font-size: 16px;
  margin-bottom: 16px;
}
.FaqQues ul {
  list-style: disc;
  padding-left: 2rem;
  margin-bottom: 16px;
}
.FaqQues li {
  color: #aeb2b1;
  font-family: var(--font-arabic-med);
  font-size: 16px;
  margin-bottom: 12px;
}
.FaqQues li span {
  color: #fff;
  font-family: var(--font-arabic-bold);
}
.FaqQues ul li:last-child {
  margin-bottom: 0px;
}
.FaqQues p:last-child {
  margin-bottom: 0px;
}
.FaqQues p a {
  color: #6fcf17;
  text-decoration: underline;
}
.FaqQues ul:last-child {
  margin-bottom: 0px;
}

/* roadmapwrapper */
.roadmapwrapper {
  background-image: url("./assets/img/roadmap/roadmap.png");
  background-size: cover;
  background-position: center;
}

.bg-plan-box {
  background: linear-gradient(122deg, #344568 -10.75%, #040404 107.7%);
  backdrop-filter: blur(40px);
}
.gtranslate_wrapper {
  position: relative !important;
  left: 68% !important;
  transform: translateX(-50%);
  background-color: red !important;
  top: 0% !important;
  width: 100% !important;
  z-index: 999 !important;
  width: 73px !important;
}
@media screen and (max-width: 650px) {
  /* .gtranslate_wrapper {
    width: 95% !important;
  } */
  .gt_container--hich1m .gt_switcher .gt_option,
  .gt_container--hich1m .gt_switcher {
    width: 163px !important;
    border-color: transparent !important;
    margin-top: 23px !important;
  }
}
@media screen and (max-width: 1150px) {
  .spider-img {
    display: none !important;
  }
}
.gt_switcher_wrapper {
  top: -17px !important;
  left: unset !important;
  right: 116px !important;
  position: absolute !important;
}

.LandingHeader .button-wrapper a:last-child {
  background: transparent !important;
  border: none !important;
}
.gt_container--hich1m .gt_switcher .gt_option,
.gt_container--hich1m .gt_switcher {
  width: 114px !important;
  border-color: transparent !important;
}

.LandingHeader .button-wrapper a:first-child,
.LandingHeader .button-wrapper a:nth-child(2) {
  border-color: transparent !important;
}
.gt_container--hich1m .gt_switcher .gt_option,
.gt_container--hich1m .gt_switcher .gt_selected {
  background: #111010 !important;
}
.gt_container--hich1m .gt_switcher a {
  font-size: 13px !important;
  font-family: var(--font-arabic-med) !important;
  color: #fff !important;
  border-color: transparent !important;
}
.gt_container--hich1m .gt_switcher a:hover {
  color: #9be402 !important;
}

@media screen and (max-width: 639px) {
  .FaqQues p,
  .FaqQues h2,
  .FaqQues li {
    font-size: 14px;
  }
  .gt_container--hich1m .gt_switcher a {
    color: #111010 !important;
  }
  .gt_container--hich1m .gt_switcher .gt_option,
  .gt_container--hich1m .gt_switcher .gt_selected {
    background: #fff !important;
  }
}

.blinkingAnimation {
  animation: 0.3s blink-animation infinite alternate;
}
@keyframes blink-animation {
  to {
    opacity: 0.8;
  }
  from {
    opacity: 1;
  }
}
