.inner-work-sign-in {
  width: 817px;
  height: 696px;
  background-color: #1a1a1b;
  border-radius: 12px;
  overflow: hidden;
  border: 1px #3c403f solid;
}

.inner-work-sign-in .left-side {
  background-image: url("../img/auth/bg-signin.png");
  background-size: cover;
  background-position: center;
}
.inner-work-sign-in .left-side .presentation {
  margin-top: 100%;
}
.inner-work-sign-in .left-side .presentation h1 {
  color: #9be402;
  font-size: 23px;
  font-family: "Arabic Pro Bold";
  line-height: 26.45px;
  word-wrap: break-word;
  text-align: center;
}
.inner-work-sign-in .left-side .presentation p {
  color: #fff;
  font-size: 17px;
  font-family: "Arabic Pro Medium";
  line-height: 21.6px;
  word-wrap: break-word;
  text-align: center;
}

/* right hand side */
.inner-work-sign-in .right-side form > h1 {
  color: var(--headingColor);
  font-size: 29px;
  font-family: "Arabic Pro Bold";
  line-height: 31.9px;
  word-wrap: break-word;
}
.inner-work-sign-in .right-side .input-wrapper {
  position: relative;
}
.inner-work-sign-in .right-side .input-wrapper label {
  color: var(--headingColor);
  font-size: 14px;
  display: block;
  font-family: "Arabic Pro Reg";
  line-height: 18.2px;
  word-wrap: break-word;
}
.inner-work-sign-in .right-side .input-wrapper input {
  height: 50px;
  width: 100%;
  background: #111010;
  border: 1px #3c403f solid;
  outline: none;
  color: var(--paragraphColor);
  font-size: 14px;
  font-family: "Arabic Pro Reg";
  line-height: 18.2px;
  word-wrap: break-word;
}

.FlagSelect .head {
  height: 50px;
  min-width: 92px;
  width: 92px;
  background: #15191f;
  border: 1px #323743 solid;
  outline: none;
  color: var(--paragraphColor);
  font-size: 14px;
  font-family: "Arabic Pro Reg";
  line-height: 18.2px;
  word-wrap: break-word;
}
.FlagSelect .list-flag-select {
  background: #0b0e2c;
  border: 1px #2c2a4a solid;
}
.FlagSelect .list-flag-select li {
  border-bottom: 1px #2c2a4a solid;
  cursor: pointer;
}
.FlagSelect .list-flag-select li:last-child {
  border-bottom: none;
}

.inner-work-sign-in .right-side .input-wrapper .eye-icon {
  position: absolute;
  right: 22px;
  top: 47px;
  cursor: pointer;
}

.inner-work-sign-in .right-side .remember-me-portion .checkbox-wrapper > label {
  color: var(--paragraphColor);
  font-size: 16px;
  font-family: "Arabic Pro Medium";
  line-height: 20px;
  word-wrap: break-word;
}
.inner-work-sign-in .right-side .remember-me-portion a {
  color: var(--headingColor);
  font-size: 16px;
  font-family: "Arabic Pro Medium";
  line-height: 20px;
  word-wrap: break-word;
}
.inner-work-sign-in .right-side button {
  background: linear-gradient(144deg, #9be402 -47.44%, #1fa83c 140.74%);
  height: 50px;
  color: #111010;
  font-size: 16px;
  font-family: "Arabic Pro Bold";
  line-height: 21.6px;
  word-wrap: break-word;
}

.inner-work-sign-in .right-side .dont-have-p {
  color: #aeb3b1;
  font-size: 16px;
  font-family: "Arabic Pro Medium";
  line-height: 20px;
  word-wrap: break-word;
}
.inner-work-sign-in .right-side .dont-have-p a {
  color: #6fcf17;
}
.inner-work-sign-in .ending-point .line-breaker span {
  border: 1px #323743 solid;
  height: 1px;
}
.inner-work-sign-in .ending-point .line-breaker p {
  color: #aeb3b1;
  font-size: 16px;
  font-family: "Arabic Pro Medium";
  line-height: 20px;
  word-wrap: break-word;
}

.inner-work-sign-in .tabs-wrapper button {
  border-radius: 8px;

  color: var(--paragraphColor);
  background: transparent;
  font-size: 14px;
  font-family: "Arabic Pro Medium";
  line-height: 18.2px;
  word-wrap: break-word;
}
.inner-work-sign-in .tabs-wrapper button.active {
  background: #252525;
  color: var(--headingColor);
  border-bottom: 1px #6fcf17 solid;
}

.inner-work-sign-in .agreement > h1 {
  color: var(--headingColor);
  font-size: 16px;
  font-family: "Arabic Pro Medium";
  line-height: 20px;
  word-wrap: break-word;
}

.inner-work-sign-in .list-inner p {
  color: #959699;
  font-size: 14px;
  font-family: "Arabic Pro Reg";
  line-height: 18.2px;
  word-wrap: break-word;
}
.inner-work-sign-in .list-inner p a {
  color: #6fcf17;
}
.inner-work-sign-in .list-inner:last-child label {
  margin-top: 0px;
}

@media screen and (max-width: 940px) {
  .inner-work-sign-in {
    grid-template-columns: 1fr;
  }
  .inner-work-sign-in .left-side {
    display: none;
  }
  .inner-work-sign-in {
    width: 100%;
    padding-bottom: 30px;
  }

  .inner-work-sign-in .right-side {
    padding: 4rem 1.5rem;
  }
  .LayerUp {
    position: static;
    height: unset;
    display: block;
  }
}
